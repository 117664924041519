import React from 'react';
import * as PropTypes from 'prop-types';
import {Button} from '@partssourceinc/react-ui-core';
import {CardAction} from 'components/CardView/StyledComponents';
import StandardInfoCard from 'components/StandardInfoCard';
import ShippingInfoCardV2 from 'components/ShippingInfoCardV2';
import PrintingOptions from './PrintingOptions';
import moment from 'moment';
const ExchangesReturnsCardFooter = (props) => {
    const {cardData,
        onPrintOptionChange,
        showReceivedDialogPopup,
        showReturnExchangeRmaInputDialogPopup,
        isPrinting,
        onRequestUpdate,
    } = props;
    switch (cardData.returnExchangeStatus) {
        case 'Process':
        {
            let infoTitle = '';
            let infoSubtext = '';
            if (cardData?.rgaType === 'RETURN') {
                infoTitle = cardData.dateReturnApprovedDenied ?
                    `Return ${cardData.isApproved === 'N' ? 'Denied' : 'Approved'} ${moment(cardData.dateReturnApprovedDenied).format('MM/DD/YYYY')}`
                    : 'Return Requested';
                infoSubtext = cardData.dateReturnApprovedDenied ? 'Pending PartsSource Review' : 'Awaiting Vendor Approval'
            } else {
                infoTitle = 'Exchange Requested';
                infoSubtext = 'Awaiting Customer Shipment';
            }
            return (
                <div>
                    <StandardInfoCard 
                        title={infoTitle} 
                        subText={infoSubtext} />                   
                    <React.Fragment>
                        <PrintingOptions isPrinting={isPrinting} cardData={cardData}
                            onPrintOptionChange={onPrintOptionChange} isExchangeOrReturn={true} />
                        { !cardData.dateReturnApprovedDenied &&
                            <CardAction>
                                <Button secondary={true} onClick={() => showReturnExchangeRmaInputDialogPopup(true)}>
                                    {cardData?.rgaType === 'RETURN' ? 'PROCESS' : 'ADD RMA#'}
                                </Button>
                            </CardAction>
                        }
                    </React.Fragment> 
                </div>
            );
        }
        case 'Pending':
            return (
                <div>
                    <ShippingInfoCardV2 showDateInfo={false} showTrackingInfo={true} 
                        title="Pending" 
                        subText="Awaiting Customer Shipment"
                        additionalText={cardData.shippingAddress} 
                        trackingNumber={cardData.trackingNumber} 
                        trackingText={cardData.shippingMethod ? cardData.shippingMethod : cardData.shipper} />   
                    <React.Fragment>
                        <PrintingOptions isPrinting={isPrinting} cardData={cardData}
                            onPrintOptionChange={onPrintOptionChange} isExchangeOrReturn={true} />
                        <CardAction>
                            <Button secondary={true} onClick={() => showReceivedDialogPopup(true)}>
                                RECEIVE</Button>
                        </CardAction>
                    </React.Fragment> 
                </div>
            );
        case 'ShippedInbound':
            return (
                <div>
                    <ShippingInfoCardV2 showDateInfo={true} showTrackingInfo={true} 
                        title="Scheduled Delivery Date"
                        subText={cardData.shippingAddress} 
                        date={cardData.estimatedDelivery} 
                        trackingNumber={cardData.trackingNumber} 
                        trackingText={cardData.shippingMethod ? cardData.shippingMethod : cardData.shipper} 
                        noDateText="Pending" noTimeText="End of Day" />
                    <React.Fragment>
                        <PrintingOptions isPrinting={isPrinting} cardData={cardData}
                            onPrintOptionChange={onPrintOptionChange} isExchangeOrReturn={true} />
                        <CardAction>
                            <Button secondary={true} onClick={() => showReceivedDialogPopup(true)}>
                                RECEIVE</Button>
                        </CardAction>
                    </React.Fragment> 
                </div>
            );
        case 'Received':
            return (
                <div>
                    <ShippingInfoCardV2 showDateInfo={true} showTrackingInfo={true} isComplete={true}
                        title="Delivered"
                        subText={cardData.shippingAddress} 
                        date={cardData.dateDelivered} 
                        trackingNumber={cardData.trackingNumber} 
                        trackingText={cardData.shippingMethod ? cardData.shippingMethod : cardData.shipper} 
                        noDateText="On Unknown Date" />
                    <React.Fragment>
                        <PrintingOptions isPrinting={isPrinting} cardData={cardData}
                            onPrintOptionChange={onPrintOptionChange} isExchangeOrReturn={true} />
                        <CardAction>
                            <Button secondary={true} onClick={() => showReceivedDialogPopup(true)}>
                                INSPECT</Button>
                        </CardAction>
                    </React.Fragment> 
                </div>
            );
        case 'Inspected':
            return (
                <div>
                    <ShippingInfoCardV2 showDateInfo={true} showTrackingInfo={true} isComplete={true}
                        title="Delivered"
                        subText={cardData.shippingAddress} 
                        date={cardData.dateDelivered} 
                        trackingNumber={cardData.trackingNumber} 
                        trackingText={cardData.shippingMethod ? cardData.shippingMethod : cardData.shipper} 
                        noDateText="On Unknown Date" />
                    <React.Fragment>
                        <PrintingOptions isPrinting={isPrinting} cardData={cardData}
                            onPrintOptionChange={onPrintOptionChange} isExchangeOrReturn={true} />
                        <CardAction>
                            <Button secondary={true} onClick={() => showReceivedDialogPopup(true)}>
                                VDF</Button>
                        </CardAction>
                    </React.Fragment> 
                </div>
            );
        case 'PendingPartsSource':
            return (
                <div>
                    <ShippingInfoCardV2 showDateInfo={false} showTrackingInfo={false} isComplete={false}
                        title={`Supplier Requested Information 
                            ${moment(cardData.statusUpdatedTimestamp ?? cardData.updatedTimestamp).format('MM/DD/YYYY')}`} 
                        subText={'PartsSource is working on your requested information'} 
                        noDateText="On Unknown Date" />
                    <React.Fragment>
                        <PrintingOptions isPrinting={isPrinting} cardData={cardData}
                            onPrintOptionChange={onPrintOptionChange} isExchangeOrReturn={true} />
                        <CardAction>
                            <Button secondary={true} onClick={onRequestUpdate}>
                                REQUEST UPDATE</Button>
                        </CardAction>
                    </React.Fragment> 
                </div>
            );
    }  
}

ExchangesReturnsCardFooter.propTypes = {
    isPrinting: PropTypes.bool,
    onPrintOptionChange: PropTypes.func,
    cardData: PropTypes.shape({
        dateCreated: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]),
        dateDelivered: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]),
        updatedTimestamp: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]),
        dateReturnApprovedDenied: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]),
        estimatedDelivery: PropTypes.string,
        shipper: PropTypes.string,
        shippingMethod: PropTypes.string,
        shippingAddress: PropTypes.string,
        status: PropTypes.string,
        trackingNumber: PropTypes.string,
        rgaType: PropTypes.string,
        rgaTypeId: PropTypes.number,
        statusId: PropTypes.number,
        returnExchangeStatus: PropTypes.string,
        isApproved: PropTypes.string,
        statusUpdatedTimestamp: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]),
    }),
    onRequestUpdate: PropTypes.func.isRequired,
    showReceivedDialogPopup: PropTypes.func.isRequired,
    showReturnExchangeRmaInputDialogPopup: PropTypes.func.isRequired,
}

export default ExchangesReturnsCardFooter;
