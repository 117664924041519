import _ from 'lodash';
import {brandFont, Loader, Popup, ProductImage} from '@partssourceinc/react-ui-core';
import {faExclamation} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {useSelector, useDispatch} from 'react-redux';
import * as PropTypes from 'prop-types';
import axios from 'axios';
import moment from 'moment';
import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import {theme} from '@partssourceinc/react-ui-core';
import {logEvent, printPackingSlipPdf, printPurchaseOrderPdf, printShippingLabelPdf, getErrorMessage, getInstrumentationObject} from 'utility';
import {setCachedAttachments} from 'reducers/attachments';
import {setCachedBids} from 'reducers/bids';
import {requestUpdate, setCachedOrders} from 'reducers/orders';
import {setLoading} from 'reducers/common';
import BidsCardDescription from 'components/CardView/BidsCardDescription';
import BidsCardFooter from 'components/CardView/BidsCardFooter';
import EsdConfirmationDialog from 'components/EsdConfirmationDialog';
import ExchangesReturnsCardDescription from 'components/CardView/ExchangesReturnsCardDescription';
import ExchangesReturnsCardFooter from 'components/CardView/ExchangesReturnsCardFooter';
import LoanersAndRentalsCardDescription from 'components/CardView/LoanersAndRentalsCardDescription';
import LoanersAndRentalsCardFooter from 'components/CardView/LoanersAndRentalsCardFooter';
import OrderLine from 'components/OrderLine';
import OrdersCardDescription from 'components/CardView/OrdersCardDescription';
import OrdersCardFooter from 'components/CardView/OrdersCardFooter';
import ReceivedDetails from 'components/Repairs/ReceivedDetails';
import RmaInputModal from 'components/RmaInputModal';
import ReturnExchangeRmaInputModal from 'components/ReturnExchangeRmaInputModal';
import RepairsCardDescription from 'components/CardView/RepairsCardDescription';
import RepairsCardFooter from 'components/CardView/RepairsCardFooter';
import {GridNames} from 'components/MultiView';
import ErrorModal from 'components/ErrorModal';
import QuotedRepairModal from 'components/Repairs/QuotedRepairModal';

import 'components/css/DataTable.css';
import 'components/css/grid.css';
import 'components/css/MultiSelect.css';
import 'components/css/primereact.css';
import 'components/css/theme.css';
import 'components/css/multiGrid.css';
import RequestUpdateDialog from 'components/RequestUpdateDialog';
import {STATUS_ENUM} from 'utils/enums';

export const CardActionType = {
    OpenBids: 'OpenBids',
    MyBids: 'MyBids',
};

const CardViewWrapper = styled.div`
    margin-top: 10px;
    display: inline-block;
    width: 100%;
`;

const Card = styled.div`
    box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.25);
    display: inline-block;
    min-height: 590px;
    height: auto;
    margin-bottom: 25px;
    margin-right: 27px;
    padding: 25px 29px;
    overflow: hidden;
    position: relative;
    width: calc(32% - 18px);

    .link {
        text-decoration: none;
        color: #005da6;
        font-weight: 700 !important;
        cursor: pointer;
    }

    @media (max-width: 1440px) {
        width: calc(50% - 15px);
    }

    @media (max-width: 1149px) {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        height: auto !important;
        min-height: 450px;
        padding-bottom: 10px;

        & > div {
            flex: 1 0 72%;
        }

        & > div:last-child {
            margin-bottom: 13px;
        }
    }

    @media (max-width: 325px) {
        button {
            margin-right: -25px;
        }

        & div {
            flex: 1 0 69%;
        }
    }

    @media screen and (max-width: ${theme.screenXsMax}) {
        .detail {
            word-break: break-word;
            font-size: 14px !important;
        }
    }
`;

const PartInfo = styled.div`
    display: inline-block;
    margin-left: 20px;
    vertical-align: top;
    max-width: 65%;
    flex: 1 2 72% !important;

    @media (max-width: 420px) {         
          display: block !important;
          max-width: unset;
        }

    @media (max-width: 1149px) {
        width: 100%;
    }
`;

const RedExclamation = styled(FontAwesomeIcon)`
    width: 1em !important;
    height: 1em !important;
    padding: 2px;
    color: #ff0000;
    border: 1px solid;
    border-radius: 50%;
    font-size: 14px;
    margin-right: 3px;
`;

const RedNotification = styled.span`
    color: #ff0000;
    font-weight: 600;
    margin-top: 5px;
    font-size: 15px;
`;

const YellowExclamation = styled(FontAwesomeIcon)`
    width: 1em !important;
    height: 1em !important;
    padding: 2px;
    color: #ffae3f;
    border: 1px solid;
    border-radius: 50%;
    font-size: 14px;
    margin-right: 3px;
`;

const YellowNotification = styled.span`
    color: #ffae3f;
    font-weight: 600;
    margin-top: 5px;
    font-size: 15px;
`;

const EmptyMessage = styled.div`
    padding-top: 50px;
    text-align: center;
    padding-bottom: 100px;
`;

const EmptyMessageTitle = styled.div`
    font-size: 18px;
    font-weight: 600;
`;

const EmptyMessageDiscription = styled.div`
    font-size: 16px;
    margin-top: 6px;
`;

const EmptyMessageImg = styled.img`
    margin-bottom: 30px;
`;

const PopupBody = styled.div`
    ${brandFont('16px')}
`;

const PopupContent = styled.p`
    ${brandFont('16px')}
`;

const PartDescription = styled.div`
    font-weight: 700;
    margin-top: 15px;
    font-size: 16px;
    margin-bottom: 10px;
    cursor: ${props => props.isLink ? 'pointer' : null};
    color: ${props => props.isLink ? '#005da6' : null}; 
`;

const PartImage = styled.span`
    display: inline-block;
    vertical-align: top;
    cursor: ${props => props.isLink ? 'pointer' : null};
`;

const CreatedWrapper = styled.div`
    float: right;

    @media (max-width: 450px) {
        float: left !important;
        width: 100%;
    }
`;

const CreatedDate = styled.span`
    @media (max-width: 450px) {
        float: left !important;
    }
`;

const CreatedTime = styled.span`
    @media (max-width: 450px) {
        float: left !important;
    }
`;

const PartInfoWrapper = styled.div`
    display: flex;
`;

const CardView = (props) => {
    const {
        cardAction,
        currentTab,
        data,
        gridKeys,
        gridName,
        loadingData,
        setLoadingData,
        onSelect,
        tabCounts,
        type,
        defaultSorting,
        sortingData,
        setSortingData,
        onRefresh,
        onUpdate,
    } = props;

    const [cardHeight] = useState(675);
    const [showCancelPopup, setShowCancelPopup] = useState(false);
    const [showUserInputPopup, setShowUserInputPopup] = useState(false);
    const [selectedCard, setSelectedCard] = useState(null);
    const [selectedOrderType, setSelectedOrderType] = useState(null);
    const [showReceivedPopup, setShowReceivedPopup] = useState(false);
    const [showRmaInputPopup, setShowRmaInputPopup] = useState(false);
    const [showReturnExchangeRmaInputPopup, setShowReturnExchangeRmaInputPopup] = useState(false);
    const [showUpdatePopup, setShowUpdatePopup] = useState(false);
    const [showEsdConfirmationPopup, setShowEsdConfirmationPopup] = useState(false);
    const cachedAttachments = useSelector(state => state.attachments.cachedAttachments);
    const [isPrinting, setIsPrinting] = useState(false);
    const [showCompanyInDescription, setShowCompanyInDescription] = useState(false);
    const [currentLineItemId, setCurrentLineItemId] = useState(null);
    const [showErrorPopup, setShowErrorPopup] = useState(false);
    const [showQuotePopup, setShowQuotePopup] = useState(false);
    const [showRequotePopup, setShowRequotePopup] = useState(false);
    const [showRequestUpdatePopup, setShowRequestUpdatePopup] = useState(false);
    const [attachments, setAttachments] = useState(null);

    const dispatch = useDispatch();

    useEffect(() => {
        const companyIdCount = _.uniq(data.map(x => x.companyId)).length;
        setShowCompanyInDescription(companyIdCount > 1);
    }, [data]);

    useEffect(() => {
        setSortingData(false);
    })

    const getAttachments = async (lineItemId) => {
        try {
            dispatch(setLoading(true));
            const response = await axios.get(`/order/${lineItemId}/attachments`);
            setAttachments(response?.data ?? null);
            dispatch(setLoading(false));
        } catch (error) {
            dispatch(setLoading(false));
            console.log(error);
        }
    }

    const declineBid = async () => {

        if (selectedCard != null) {

            const declineBidRequest = {
                orderId: selectedCard.orderId,
                lineItemId: selectedCard.lineItemId,
                companyId: selectedCard.companyId,
            }

            setShowCancelPopup(false);

            await axios.post(`/bid/decline`, declineBidRequest);

            dispatch(setCachedBids([]));
            logEvent('SUBMIT BID', {
                'Ref number': selectedCard.lineItemId,
                'Vendor ID': selectedCard.companyId,
                'Bid State': 'No Bid',
            });

            onRefresh();
        }
        setShowCancelPopup(false);
    }

    const onSelectCard = (card) => {
        onSelect(card);
    }

    const renderNotification = (cardData) => {
        switch (gridName) {
            case GridNames.bids.id: {
                const dateEntered = new moment.utc(cardData.dateEnteredUTC).toDate();
                const dateNow = moment().utc().toDate();
                const diff = (dateNow - dateEntered);
                const diffMins = Math.floor((diff / 1000) / 60);
                const isPast30Minutes = diffMins > 30;

                return (
                    (cardData.urgencyDescription === 'CHD' || cardData.urgencyDescription === 'STAT') ? (
                        <div>
                            <RedExclamation icon={faExclamation} />
                            <RedNotification> Urgent</RedNotification>
                        </div>
                    ) : currentTab !== 'MyBids' && isPast30Minutes ?
                        <div>
                            <YellowExclamation icon={faExclamation} />
                            <YellowNotification> Past 30 Minutes</YellowNotification>
                        </div>
                        : null
                );
            }
            case GridNames.orders.id: {
                if (currentTab !== 'AwaitingReview' && cardData.isUrgent) {
                    return (
                        <div>
                            <RedExclamation icon={faExclamation} />
                            <RedNotification> Urgent</RedNotification>
                        </div>
                    )
                }

                let notification = '';
                if (cardData.isPastDue) {
                    notification += 'Past Due';
                } else if (cardData.hasMissingData) {
                    notification += 'Missing Info';
                } else if (currentTab === 'AwaitingReview') {
                    notification += '14 Day Review';
                }

                if (currentTab === 'AwaitingReview' && cardData.isUrgent) {
                    notification += notification ? ', Urgent' : 'Urgent';
                    return (
                        <div>
                            <RedExclamation icon={faExclamation} />
                            <RedNotification> {notification}</RedNotification>
                        </div>
                    )
                } else if ((currentTab === 'AwaitingReview' || currentTab === 'Shipped') && notification) {
                    return (
                        <div>
                            <YellowExclamation icon={faExclamation} />
                            <YellowNotification> {notification}</YellowNotification>
                        </div>
                    )
                }

                break;
            }
            case GridNames.loanersAndRentals.id: {
                let notification = '';
                const isUrgent = cardData.urgencyDescription === 'CHD' || cardData.urgencyDescription === 'STAT';

                if (isUrgent) {
                    notification += notification ? ', Urgent' : 'Urgent';
                    return (
                        <div>
                            <RedExclamation icon={faExclamation} />
                            <RedNotification> {notification}</RedNotification>
                        </div>
                    )
                }

                break;
            }
            case GridNames.repairs.id: {
                let notification = '';               
                if (cardData.isPastDue) {
                    notification += 'Past Due';
                    return (
                        <div>
                            <YellowExclamation icon={faExclamation} />
                            <YellowNotification> {notification}</YellowNotification>
                        </div>
                    )
                } else if (cardData.isUrgent) {
                    notification += notification ? ', Urgent' : 'Urgent';
                    return (
                        <div>
                            <RedExclamation icon={faExclamation} />
                            <RedNotification> {notification}</RedNotification>
                        </div>
                    )
                }

                break;
            }
            case GridNames.rgaOrders.id: {
                let notification = 'Past Due';
                if (cardData.isPastDue) {
                    return (
                        <div>
                            <YellowExclamation icon={faExclamation} />
                            <YellowNotification> {notification}</YellowNotification>
                        </div>
                    )
                }
               
            }
           
        }
    }

    const showProcessPopup = (card, orderType) => {
        card.vendorToCustomerTrackingNumber = card.vendorToCustomerTrackingNumber ?? card.trackingNumber;
        setSelectedCard(card);
        setSelectedOrderType(orderType);
        setShowUserInputPopup(true);
    }

    const showUpdatePopupDialog = async (card, orderType) => {
        if (card.orderType === 'REPAIR') {
            await getAttachments(card.lineItemId);
        }
        card.vendorToCustomerTrackingNumber = card.vendorToCustomerTrackingNumber ?? card.customerTrackingNumber;
        setSelectedCard(card);
        setSelectedOrderType(orderType);
        setShowUpdatePopup(true);
    }

    const showReceivedDialogPopup = (card) => {
        setSelectedCard(card);
        setShowReceivedPopup(true);
    }

    const showRmaInputDialogPopup = (card) => {
        setSelectedCard(card);
        setShowRmaInputPopup(true);
    }

    const showReturnExchangeRmaInputDialogPopup = (card) => {
        setSelectedCard(card);
        setShowReturnExchangeRmaInputPopup(true);
    }

    const showEsdConfirmationDialogPopup = (card, orderType) => {
        setSelectedCard(card);
        setSelectedOrderType(orderType);
        setShowEsdConfirmationPopup(true);
    }

    const showQuoteDialogPopup = (card) => {
        setSelectedCard(card);
        setShowQuotePopup(true);
    }

    const showRequoteDialogPopup = (card) => {
        setSelectedCard(card);
        setShowRequotePopup(true);
    }

    const onPrintOptionChange = async (value, cardData, availableOptions) => {
        const {lineItemId, billingPo, gsaItem, orderNumber} = cardData;
        setCurrentLineItemId(lineItemId);
        try {
            switch (value) {
                case 'PACKINGSLIP':
                    if (cardData.statusId === STATUS_ENUM.NOSALE) {
                        await printPackingSlipPdf(orderNumber, true, setIsPrinting);
                        break;
                    } else {
                        await printPackingSlipPdf(billingPo, false, setIsPrinting);
                        break;
                    }
                    
                case 'PURCHASEORDER':
                    await printPurchaseOrderPdf(lineItemId, billingPo, gsaItem, setIsPrinting);
                    break;
                case 'SHIPPINGLABEL':
                    await printShippingLabelPdf(lineItemId, setIsPrinting);
                    break;
                case 'PRINTALL':
                    if (availableOptions && availableOptions.length > 0) {
                        if (availableOptions.includes('PACKINGSLIP')) {
                            if (cardData.statusId === STATUS_ENUM.NOSALE) {
                                await printPackingSlipPdf(orderNumber, true, setIsPrinting);
                                break;
                            } else {
                                await printPackingSlipPdf(billingPo, false, setIsPrinting);
                                break;
                            }
                        }

                        if (cardData.hasShippingLabel && availableOptions.includes('SHIPPINGLABEL')) {
                            await printShippingLabelPdf(lineItemId, setIsPrinting);
                        }

                        if (availableOptions.includes('PURCHASEORDER')) {
                            await printPurchaseOrderPdf(lineItemId, billingPo, gsaItem, setIsPrinting);
                        }
                    }
                    break;
            }
        } catch {
            setLoadingData(false);
        }
    }

    let gridKey = gridKeys.filter((x) => x.id === currentTab)[0];
    const tab = tabCounts.filter((x) => x.id === currentTab)[0];
    let showEmptyMessage = (data || {}).length === 0;

    if (!gridKey && data.length === 0) {
        showEmptyMessage = true;
    }

    let hasActionButtons = cardAction || (gridKey || {}).cardAction;

    if (hasActionButtons === 'Repair' && data.groupName !== 'Initiated') hasActionButtons = false;

    const onOrderLineSave = async (orderLine) => {
        setLoadingData(true);
        orderLine.noteType = orderLine.orderType === 'PART ORDER' ? 'Order' : orderLine.orderType;
        const attachmentsWereAdded = cachedAttachments.length > 0;

        let request = {
            orderId: orderLine.orderNumber,
            companyId: orderLine.companyId,
            orderType: orderLine.orderType,
            lineItems: [],
        };

        setShowUserInputPopup(false);
        request.lineItems.push({
            lineItemId: orderLine.lineItemId,
            trackingNumber: orderLine.vendorToCustomerTrackingNumber,
            vendorOrderNumber: orderLine.vendorOrderNumber,
            noteType: orderLine.noteType,
            note: orderLine.note,
            vendorRmaNumber: orderLine.vendorRmaNumber,
            estimatedShipDate: orderLine.estimatedShipDate,
            shipDateReasonId: orderLine.shipDateReasonId,
            serialNumber: orderLine.serialNumber,
        });

        try {
            const response = await axios.post(`/order`, request)
            if (response.status === 201) {
                cachedAttachments.map((attachment) => {
                    axios.post(`/order/${orderLine.lineItemId}/attachment`, attachment)
                })
    
                dispatch(setCachedOrders([]));
                dispatch(setCachedAttachments([]));
    
                handleLogEvent(orderLine, attachmentsWereAdded);
    
                if (onUpdate) {
                    await onUpdate(orderLine.lineItemId);
                } else {
                    await onRefresh();
                }
                
                setShowUpdatePopup(false);
            } else {
                setShowUserInputPopup(true);
            }
        } catch (error) {
            console.log(error);
            setShowErrorPopup(true);
        }
    }

    const processLoanersEventLog = (orderLine, attachmentsAdded, page) => {
        if (currentTab === 'Ordered') {
            logEvent('LOANER PROCESS', {
                'Ref Number': orderLine.lineItemId,
                'Page': page,
                'Tab': currentTab,
                ...getInstrumentationObject('Serial Number', selectedCard.serialNumber, orderLine.serialNumber),
                ...getInstrumentationObject('Ship Tracking Number', selectedCard.vendorToCustomerTrackingNumber,
                    orderLine.vendorToCustomerTrackingNumber),
                ...getInstrumentationObject('Estimated Ship Date', selectedCard.estimatedShipDate, orderLine.estimatedShipDate),
                ...getInstrumentationObject('Ship Date Reason', selectedCard.shipDateReasonId, orderLine.shipDateReasonId),
                ...(attachmentsAdded && {'Attachments': 'Add'}),
            });
        } else if (currentTab === 'Inspected') {
            logEvent('RECEIVE ITEM', {
                'Ref number': orderLine.lineItemId,
                'Order Number': orderLine.orderNumber,
                'RGA ID': orderLine.rgaId,
                'Tab': currentTab,
                'Page': page,
            });
        }
    }

    const handleLogEvent = (orderLine, attachmentsAdded) => {
        const page = window.location.pathname.split('/')[1];

        if (page === 'loaners-rentals') {
            processLoanersEventLog(orderLine, attachmentsAdded, page);
        } else {
            if (page === 'repairs' && (currentTab === 'Evaluating' || currentTab === 'Quoted' 
                || currentTab === 'Backordered' || currentTab === 'Repairing')) {
                logEvent('Repair Update', {
                    'Ref number': orderLine.lineItemId,
                    'Vendor ID': orderLine.companyId,
                    'Order State': 'Process',
                    'Tab': currentTab,
                    'Action': 'OK',
                });
            } else {
                logEvent('Order Update', {
                    'Ref number': orderLine.lineItemId,
                    'Vendor ID': orderLine.companyId,
                    'Order State': 'Process',
                    'Tab': currentTab,
                    'Action': 'OK',
                });
            }
        }
    }

    const onReceiveUpdate = async () => {
        setShowReceivedPopup(false);
        onRefresh();
    }

    const onRmaUpdate = async () => {
        setShowRmaInputPopup(false);
        onRefresh();
    }

    const onReturnExchangeRmaUpdate = async () => {
        setShowReturnExchangeRmaInputPopup(false);
        onRefresh();
    }

    const onEsdConfirmationUpdate = async () => {
        setShowEsdConfirmationPopup(false);
        onRefresh();
    }

    const onSubmitRequote = async () => {
        setShowRequotePopup(false);
        onRefresh();
    }

    const onSubmitQuote = async () => {
        setShowQuotePopup(false);
        onRefresh();
    }

    const onRequestUpdate = (cardData) => {
        dispatch(requestUpdate(cardData.lineItemId, cardData.rgaType))
            .then(() => setShowRequestUpdatePopup(true));
    }

    const renderCardHeader = (cardData) => {

        let timeStamp = type === 'exchange-return' ?
            cardData?.createdTimeStamp :
            type === 'repair' ?
                cardData?.dateCreated :
                cardData?.dateEntered;
        timeStamp = type === 'loaner' ? cardData?.dateCreated : timeStamp;

        return (
            <React.Fragment>
                <div>
                    {cardData.orderType === 'LOANER' ?
                        <span className="order-label">{`ORDER #${cardData.orderNumber}`}</span> :
                        <span className="order-label">{`REFERENCE #${cardData.lineItemId ?? cardData.rgaId}`}</span>
                    }
                    <span className="view-details" onClick={() => onSelectCard(cardData)}>
                        View Details
                    </span>
                    <CreatedWrapper>
                        <CreatedDate className="created-label">
                            {`${gridName === GridNames.bids.id ? 'REQUESTED' : 'CREATED'} ${moment(timeStamp).format('MM/D/YYYY')}`}
                        </CreatedDate>
                        <br />
                        <CreatedTime className="created-label-date">{moment(timeStamp).format('h:mm A')}</CreatedTime>
                    </CreatedWrapper>
                </div>
                {renderNotification(cardData)}
            </React.Fragment>);
    }

    const renderDescription = (cardData) => {
        return (
            <React.Fragment>
                
                <PartDescription isLink={cardData.imageDefaultUrl} onClick={() =>
                    (cardData.imageDefaultUrl ?
                        window.open(`${process.env.REACT_APP_PARTSSOURCE_URL}${cardData.imageDefaultUrl}`, '_blank') : undefined)}>
                    {cardData.isContractProOption && (
                        <div className="contract-badge">
                            <span>CONTRACT</span>
                        </div>
                    )}
                    {cardData.description}
                </PartDescription>
                <PartInfoWrapper>
                    <PartImage isLink={cardData.imageDefaultUrl} onClick={() =>
                        (cardData.imageDefaultUrl ?
                            window.open(`${process.env.REACT_APP_PARTSSOURCE_URL}${cardData.imageDefaultUrl}`, '_blank') : null)}>
                        {cardData.isServiceItem ? (
                            <img src="/images/icn_repair.png" alt="Service Repair" style={{width: 60, marginLeft: 20}} />
                        ) : (
                            <ProductImage style={{width: '100px'}} url={cardData.imagePath} altText={cardData.imageAlt} />
                        )}
                    </PartImage>
                    <PartInfo className="part-info"> 
                        {gridName === GridNames.repairs.id &&
                            <RepairsCardDescription
                                cardData={cardData}
                                showCompanyInDescription={showCompanyInDescription} />}

                        {(gridName === GridNames.orders.id || gridName === GridNames.ordersHistory.id) &&
                            <OrdersCardDescription
                                cardData={cardData}
                                showCompanyInDescription={showCompanyInDescription} />}

                        {gridName === GridNames.bids.id &&
                            <BidsCardDescription
                                cardData={cardData}
                                showCompanyInDescription={showCompanyInDescription} />}

                        {gridName === GridNames.rgaOrders.id &&
                            <ExchangesReturnsCardDescription
                                cardData={cardData} />}

                        {gridName === GridNames.loanersAndRentals.id &&
                            <LoanersAndRentalsCardDescription
                                cardData={cardData}
                                showCompanyInDescription={showCompanyInDescription} />}
                    </PartInfo>
                </PartInfoWrapper>
            </React.Fragment>
        )
    }

    const renderCardFooter = (cardData) => {

        switch (gridName) {
            case GridNames.repairs.id:
                return (<RepairsCardFooter
                    cardData={cardData}
                    isPrinting={isPrinting && currentLineItemId === cardData?.lineItemId}
                    onPrintOptionChange={onPrintOptionChange}
                    setSelectedCard={setSelectedCard}
                    showReceivedDialogPopup={() => showReceivedDialogPopup(cardData)}
                    showRmaInputPopup={() => showRmaInputDialogPopup(cardData)}
                    showUpdatePopup={showUpdatePopupDialog}
                    showEsdConfirmationDialogPopup={() => showEsdConfirmationDialogPopup(cardData, 'repair')}
                    showQuoteDialogPopup={() => showQuoteDialogPopup(cardData)}
                    showRequoteDialogPopup={() => showRequoteDialogPopup(cardData)}
                />);
            case GridNames.ordersHistory.id:
            case GridNames.orders.id:
                return (<OrdersCardFooter
                    cardData={cardData}
                    isPrinting={isPrinting && currentLineItemId === cardData?.lineItemId}
                    onSelectCard={onSelectCard}
                    showProcessPopup={() => showProcessPopup(cardData, 'part')}
                    onPrintOptionChange={onPrintOptionChange}
                    showEsdConfirmationDialogPopup={() => showEsdConfirmationDialogPopup(cardData, 'part')} />);
            case GridNames.bids.id:
                return (<BidsCardFooter cardData={cardData}
                    onSelectCard={onSelectCard}
                    setSelectedCard={setSelectedCard}
                    setShowCancelPopup={setShowCancelPopup} />);
            case GridNames.rgaOrders.id:
                return (<ExchangesReturnsCardFooter
                    cardData={cardData}
                    onSelectCard={onSelectCard}
                    isPrinting={isPrinting && currentLineItemId === cardData?.lineItemId}
                    onPrintOptionChange={onPrintOptionChange}
                    showReceivedDialogPopup={() => showReceivedDialogPopup(cardData)}
                    showRmaInputDialogPopup={() => showRmaInputDialogPopup(cardData)}
                    showReturnExchangeRmaInputDialogPopup={() => showReturnExchangeRmaInputDialogPopup(cardData)}
                    onRequestUpdate={() => onRequestUpdate(cardData)} />);
            case GridNames.loanersAndRentals.id:
                return (<LoanersAndRentalsCardFooter
                    cardData={cardData}
                    isPrinting={isPrinting && currentLineItemId && currentLineItemId === cardData?.lineItemId}
                    onPrintOptionChange={onPrintOptionChange}
                    showReceivedDialogPopup={() => showReceivedDialogPopup(cardData)}
                    showRmaInputDialogPopup={() => showRmaInputDialogPopup(cardData)}
                    showProcessPopup={() => showProcessPopup(cardData, 'loaner')} />);
        }
    }

    const renderCard = (cardData, index) => {
        const gridHeight = gridKey && gridKey.height ? gridKey.height : null;
        const height = gridHeight || cardHeight || '640';

        return (
            <Card key={index} className={hasActionButtons ? 'card-view' : 'card-view no-action-buttons'} style={{height: height + 'px'}}>
                {renderCardHeader(cardData)}
                {renderDescription(cardData)}
                {renderCardFooter(cardData)}
            </Card>
        );
    }

    const getGridName = (gridId) => {
        return GridNames[gridId].name;
    }

    const hideErrorPopup = () => {
        setShowErrorPopup(false);
    }

    const orderData = () => defaultSorting ? defaultSorting(data, currentTab) : data;

    const isExchangeReturnRGA = (selectedCard?.rgaType?.toUpperCase() === 'RETURN' ||
        selectedCard?.rgaType?.toUpperCase() === 'EXCHANGE');

    const isLoaner = selectedCard?.orderType?.toUpperCase() === 'LOANER';

    const isRepairOrLoaner = type === 'loaner' || type === 'repair';

    let tabDisplay = gridKey?.tabDisplay === undefined ? '' : gridKey?.tabDisplay;

    return (
        <div className="multi-grid-page multi-grid-page--sticky">
            {(loadingData || sortingData) &&
                <Loader style={{marginLeft: 'auto', marginRight: 'auto', width: '75px'}} />
            }
            {(!loadingData && !sortingData) &&
                <div className="grid-bottom">
                    <React.Fragment>
                        {!loadingData && (showEmptyMessage && data.length === 0) ? (
                            <EmptyMessage>
                                <EmptyMessageImg src="/images/icon_empty-queue.svg" />
                                <EmptyMessageTitle>
                                    {`No Active ${tabDisplay} ${getGridName(gridName)} Found`}
                                </EmptyMessageTitle>
                                <EmptyMessageDiscription>
                                    {`You currently have no active ${tabDisplay} orders`}
                                </EmptyMessageDiscription>
                            </EmptyMessage>
                        ) : (
                            <div className="custom-primereact" style={{paddingBottom: 0}}>{
                                <CardViewWrapper className="card-view-wrapper">{
                                    orderData().map((x, i) => renderCard(x, i))
                                }</CardViewWrapper>
                            }</div>)}
                    </React.Fragment>
                    {showCancelPopup && <Popup
                        confirmText="Yes"
                        show={showCancelPopup}
                        onCancel={() => setShowCancelPopup(false)}
                        onConfirm={() => declineBid()}
                        hideButtons={false}>
                        <PopupBody>
                            <div>
                                <PopupContent>Are you sure you want to decline this bid?</PopupContent>
                            </div>
                        </PopupBody>
                    </Popup>}

                    {showUserInputPopup && <OrderLine
                        handleOnCancel={() => setShowUserInputPopup(false)}
                        handleOnSave={(e) => onOrderLineSave(e)}
                        type={selectedOrderType}
                        showSerialNumberInput={selectedCard.orderType === 'LOANER'}
                        showVendorDetails={selectedCard.orderType !== 'LOANER'}
                        rmaRequired={selectedCard.vendorRmaRequired}
                        order={{id: selectedCard.orderNumber, ...selectedCard}} />
                    }
                
                    {showReceivedPopup && selectedCard && <ReceivedDetails
                        show={showReceivedPopup}
                        orderId={type === 'exchange-return' ? selectedCard.orderId : selectedCard.orderNumber}
                        lineItemId={selectedCard.lineItemId}
                        trackingNumber={isExchangeReturnRGA ? selectedCard.trackingNumber : isRepairOrLoaner
                            ? selectedCard.customerToVendorTrackingNumber : selectedCard.vendorTrackingNumber}
                        rgaId={selectedCard.rgaId}
                        rgaType={selectedCard?.rgaType ?? selectedCard?.orderType}
                        onSubmit={(e) => onRmaUpdate(e)}
                        onCancel={() => setShowReceivedPopup(false)}
                        orderType={selectedCard.orderType}
                        type={type}
                        rgaNumber={selectedCard.rgaNumber}
                        isRgaOrder={isExchangeReturnRGA || isLoaner}
                        poNumber={selectedCard.displayPo} 
                        itemDescription={selectedCard.description} 
                        partNumber={selectedCard.partNumber}
                        tab={tab} />
                    }

                    {showReturnExchangeRmaInputPopup && <ReturnExchangeRmaInputModal
                        show={showReturnExchangeRmaInputPopup}
                        orderId={type === 'exchange-return' ? selectedCard.orderId.toString() : selectedCard.orderNumber.toString()}
                        lineItemId={selectedCard.lineItemId}
                        rgaId={selectedCard.rgaId}
                        onSubmit={(e) => onReturnExchangeRmaUpdate(e)}
                        onCancel={() => setShowReturnExchangeRmaInputPopup(false)}
                        type={selectedCard.rgaType === 'RETURN' ? 'Return' : 'Exchange'}
                        typeId={selectedCard.rgaType === 'RETURN' ? 2 : 1}
                        isDirectShip={selectedCard.isDirectShip}
                        previousRmaNumber={selectedCard.rmaNumber}
                        rmaNumberRequired={selectedCard.returnRmaRequired}
                        poNum={selectedCard.displayPo}
                        returnQty={selectedCard.quantity}
                        rgaNumber={selectedCard.rgaNumber}
                    />}

                    {showRmaInputPopup && <RmaInputModal
                        show={showRmaInputPopup}
                        orderId={type === 'exchange-return' ? selectedCard.orderId : selectedCard.orderNumber}
                        lineItemId={selectedCard.lineItemId}
                        poNum={selectedCard.displayPo}
                        rgaId={selectedCard.rgaId}
                        onSubmit={(e) => onReceiveUpdate(e)}
                        onCancel={() => setShowRmaInputPopup(false)}
                        type={type}
                        rgaNumber={selectedCard.rgaNumber}
                    />}

                    {showUpdatePopup &&
                        <OrderLine
                            handleOnCancel={() => setShowUpdatePopup(false)}
                            handleOnSave={(e) => onOrderLineSave(e)}
                            order={{id: selectedCard.orderNumber, ...selectedCard, attachments: attachments}}
                            type={type} />
                    }

                    {showEsdConfirmationPopup &&
                        <EsdConfirmationDialog
                            show={showEsdConfirmationPopup}
                            onCancel={() => setShowEsdConfirmationPopup(false)}
                            onSubmit={(e) => onEsdConfirmationUpdate(e)}
                            orderId={selectedCard.orderNumber}
                            lineItemId={selectedCard.lineItemId}
                            date={selectedCard.estimatedShipDate}
                            trackingNumber={selectedCard.trackingNumber}
                            shipDateReasonId={selectedCard.shipDateReasonId}
                            type={selectedOrderType}
                            poNum={selectedCard.displayPo} />
                    }

                    {showQuotePopup && <QuotedRepairModal
                        orderId={selectedCard.orderNumber}
                        lineItemId={selectedCard.lineItemId}
                        onCancel={() => setShowQuotePopup(false)}
                        onSubmit={onSubmitQuote}
                        rgaType={selectedCard?.rgaType?.toUpperCase()}
                        rgaHeaderId={selectedCard.rgaId}
                        isRequote={false}
                        statusId={selectedCard.statusId}
                        poNumber={selectedCard.displayPo}
                        show={true} />
                    }

                    {showRequotePopup && <QuotedRepairModal
                        orderId={selectedCard.orderNumber}
                        lineItemId={selectedCard.lineItemId}
                        rgaHeaderId={selectedCard.rgaId}
                        onSubmit={onSubmitRequote}
                        rgaType={selectedCard?.rgaType?.toUpperCase()}
                        onCancel={() => setShowRequotePopup(false)}
                        isRequote={true}
                        statusId={selectedCard.statusId}
                        poNumber={selectedCard.displayPo}
                        show={true} />
                    }
                    <RequestUpdateDialog show={showRequestUpdatePopup} onClose={() => setShowRequestUpdatePopup(false)} />
                </div>
            }
            <ErrorModal
                show={showErrorPopup}
                popupTitle={'Order Save'}
                popupMessage={getErrorMessage('order')}
                onCancel={() => hideErrorPopup()} />
        </div>
    );
}

CardView.propTypes = {
    cardAction: PropTypes.oneOf(Object.values(CardActionType)),
    data: PropTypes.arrayOf(
        PropTypes.shape()
    ),
    gridKeys: PropTypes.arrayOf(
        PropTypes.shape({
            cardAction: PropTypes.string,
            fieldName: PropTypes.string,
            id: PropTypes.string,
            tabDisplay: PropTypes.string,
            value: PropTypes.string,
        })
    ),
    gridName: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    loadingData: PropTypes.bool,
    sortingData: PropTypes.bool,
    setLoadingData: PropTypes.func,
    setSortingData: PropTypes.func,
    onRequesterToggle: PropTypes.func,
    onSelect: PropTypes.func,
    onRefresh: PropTypes.func,
    onUpdate: PropTypes.func,
    showShipInfo: PropTypes.bool,
    subLabel: PropTypes.string,
    type: PropTypes.string,
    currentTab: PropTypes.string,
    tabCounts: PropTypes.arrayOf(
        PropTypes.shape({

        })
    ),
    defaultSorting: PropTypes.func,
};

export default CardView
